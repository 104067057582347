import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
// import { navigate } from "gatsby";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import bannerMobile from "../../images/banner-mobile-service.png";
import banner from "../../images/Service-Academy-Group1.png";
import { color, typography } from "../../shared/style";

export const Banner = ({ title, description, button_label, ...props }) => {
  // const redirectPage = (url) => {
  //   window.location.href = "#";
  // };

  return (
    <WrapperSection>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h2"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
          size={120}
          lineHeight={120}
        >
          <RichText render={title.raw} htmlSerializer={htmlSerializer} />
        </TextExtend>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.s3}
          lineHeight={26}
        >
          <RichText render={description?.raw} htmlSerializer={htmlSerializer} />
        </Description>
        {/* <GroupButton>
          <ButtonExtended
            onClick={() => redirectPage("more-service")}
            type="primary"
          >
            {button_label}
          </ButtonExtended>
        </GroupButton> */}
      </Content>
    </WrapperSection>
  );
};

// const GroupButton = styled.div`
//   display: flex;
//   justify-content: center;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
//   @media (max-width: 650px) {
//     padding: 0 50px 99px;
//   }
// `;
// const ButtonExtended = styled(Button)`
//   border-radius: 40px;
//   height: 65px;
//   white-space: nowrap;
//   margin-top: 30px;
//   padding: 20px 45px;

//   @media (max-width: 650px) {
//     margin-top: 40px;
//   }
// `;
const TextExtend = styled(Text)`
  margin-top: 90px;
  padding-top: 208px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
    padding: 20px 0;
    line-height: 74px;
    font-size: 74px !important;
    span {
      font-size: 74px !important;
      line-height: 74px;
    }
  }
  @media (max-width: 650px) {
    padding-top: 120px;
    width: 77%;
    margin-top: 80px;
    padding-bottom: 0;
    line-height: 62px;
    font-size: 62px !important;
    span {
      line-height: 62px;
      font-size: 62px !important;
    }
  }
`;
const WrapperSection = styled.div`
  min-height: 700px;
  margin-top: -90px;
  box-sizing: border-box;
  display: flex;
  background-image: url(${(props) => (props.banner ? props?.banner : banner)});
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    min-height: 630px;
    background-image: url(${(props) => props.banner ? props?.banner : bannerMobile});
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 938px;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    margin: auto 20px !important;
  }
`;

const Description = styled(Text)`
  width: 72%;
  margin-top: 10px;
  padding-bottom: 220px;
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 83%;
    margin-top: 0px;
  }
  @media (max-width: 650px) {
    padding-bottom: 160px;
    margin-top: 16px;
    width: 95%;
  }
`;

Banner.propsType = {
  data: PropTypes.shape({
    title: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          type: PropTypes.string,
          spans: PropTypes.arrayOf(),
        })
      ),
    }),
    description: PropTypes.shape({
      raw: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          type: PropTypes.string,
          spans: PropTypes.arrayOf(),
        })
      ),
    }),
  }),
};

Banner.defaultProps = {
  data: {
    title: {
      raw: [
        {
          type: "preformatted",
          text: "Help entrepreneurs build startups from 0 to 1",
          spans: [],
        },
      ],
    },
    description: {
      raw: [
        {
          type: "preformatted",
          text:
            "Our services offer is specially designed to support entrepreneurs, startups, solopreneurs, growth companies, digital nomads and everyone who needs a helping hand to realize their dreams.",
          spans: [],
        },
      ],
    },
  },
};
