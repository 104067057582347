import React from "react";
import styled from "styled-components";
import { Banner } from "./Banner";
import DetailService from "./DetailService";
import OurProcess from "./OurProcess";
import ServicePackages from "./ServicePackages";
import StaupsWithUs from "./StaupsWithUs";
import Summary from "./Sumary";
import ExclusiveOffer from "./ExclusiveOffer";

export const ServiceContainer = ({ data }) => {

  return (
    <WrapperLayout>
      <Banner
        title={data?.banner_title}
        description={data?.banner_description}
        button_label={data?.banner_button_label?.text}
      />
      <DetailService data={data?.body[0] === undefined ? {} : data?.body[0]} />
      <ServicePackages
        data={data?.body1[0] === undefined ? {} : data?.body1[0]}
      />
      <OurProcess data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      <StaupsWithUs
        review_list={data?.body4[0] === undefined ? {} : data?.body4[0]}
      />
      {data?.body5[0] && (
      <ExclusiveOffer
        data={data?.body5[0] === undefined ? {} : data?.body5[0]}
      />
      )}
      <Summary data={data?.body6[0] === undefined ? {} : data?.body6[0]} />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
`;
