import React, { useEffect, useState } from "react";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Button } from "../../components/Button/Button";
import brand1 from "../../images/Service-staup-barnd-1.png";
import brand2 from "../../images/Service-staup-barnd-2.png";
import brand3 from "../../images/Service-staup-barnd-3.png";
import CourseItem from "./CoreseItem";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";

const ExclusiveOffer = ({ data, ...props }) => {
  const [width] = useWindowSize();
  const [listShow, setListShow] = useState();
  const { items, primary } = data || {};
  const redirectPage = (url) => {
    window.location.href = `/${url}`;
  };

  useEffect(() => {
    if(items) {
      const newList = [...items];
      if (width < 650) {
        setListShow(newList.splice(0, 3));
      } else {
        setListShow(newList);
      }
    }
  }, [width]);
  if (data===undefined || !items || !primary) return null;
  return (
    <WrapperSection>
      <WrapHeader>
        <TitleHeader
          tag="h3"
          weight={typography.weight.regular1}
          color={color.primary.blue}
          font={typography.type.code}
          size={typography.size.code}
          capital="uppercase"
          lineHeight={90}
        >
          <RichText
            render={primary?.title.raw}
            htmlSerializer={htmlSerializer}
          />
        </TitleHeader>
        <BoxDescriptionHeader>
          <DescriptionHeader
            tag="p"
            weight={typography.weight.regular}
            color={color.netraul.black100}
            font={typography.type.primary}
            size={typography.size.s3}
            lineHeight={26}
          >
            {primary?.description?.text}
            {/* <RichText
            render={primary?.description?.raw}
            htmlSerializer={htmlSerializer}
          /> */}
          </DescriptionHeader>
          <ButtonContainerHeader>
            <ButtonExtended
              onClick={() => redirectPage("more-services")}
              color={color.primary.blue}
            >
              Load more services
            </ButtonExtended>
          </ButtonContainerHeader>
        </BoxDescriptionHeader>
      </WrapHeader>
      <WrapBody>
        {listShow?.map((item, index) => (
          <CourseItem item={item?.offer?.document} key={index} />
        ))}
        <ButtonContainer>
          <ButtonExtended
            onClick={() => redirectPage("more-services")}
            type="primary"
          >
            Load more services
          </ButtonExtended>
        </ButtonContainer>
      </WrapBody>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  margin-top: 150px;
  box-sizing: border-box;
  padding: 150px 131px 114px;
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    padding: 70px 20px 100px;
    margin-top: 80px;
  }
  @media (max-width: 992px) {
    padding: 70px 20px 100px;
  }
  @media (max-width: 650px) {
    padding: 70px 20px 100px;
    margin-top: 80px;
  }
`;

const WrapHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  span {
    display: block;
  }
  /* @media (max-width: 1200px) {
    align-items: flex-start;
  } */
  @media (max-width: 650px) {
    flex-direction: column;
  }
  `;
const TitleHeader = styled(Text)`
  flex: 0 0 39.333%;
  max-width: 39.333%;
  letter-spacing: -0.015em;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    flex: 0 0 50%;
    max-width: 50%;
    font-size: 72px !important;
    line-height: 72px;
    letter-spacing: -0.015em;
    font-weight: 500;
  }
  @media (max-width: 992px) {
    font-size: 56px !important;
    line-height: 56px;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
    font-size: 62px !important;
    line-height: 62px;
    letter-spacing: -0.015em;
    font-weight: 500;
  }
  `;
  const BoxDescriptionHeader = styled.div`
  max-width: 44.333%;
  flex: 0 0 44.333%;
  padding-left: 123px;
  box-sizing: border-box;
  /* margin-bottom: -42px; */
  /* div {
    margin-bottom: 16px;
  } */

  @media (max-width: 1200px) {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 51px;
    padding: 0;
    /* color: ${color.netraul.black100}; */
    margin-top: 30px;
  }
  `;
const DescriptionHeader = styled(Text)`
margin-top: 5px;
`;
const ButtonContainerHeader = styled.div`
  max-width: 16.334%;
  flex: 0 0 16.334%;
  box-sizing: border-box;
  margin-top: 22px;
  button {
    padding: 19.5px 45px;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
    flex: 0 0 100%;
    /* margin-top: -10px; */
  }
  @media (max-width: 1100px) {
    /* margin-top: -30px; */
  }
  @media (max-width: 992px) {
    padding: 0;
    /* margin-top: -66px; */
  }
  @media (max-width: 650px) {
    display: none;
    /* margin-top: 2px; */
  }
`;
const WrapBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
  margin: 0 -17px;
  margin-top: 120px;

  @media (max-width: 650px) {
    margin-top: 50px;
    margin: 0 -20px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`;

const ButtonExtended = styled(Button)`
  border-radius: 40px;
  height: 65px;
  white-space: nowrap;
  padding: 20px 45px;
`;

ExclusiveOffer.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.shape(),
      description: PropTypes.shape(),
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.string,
        title: PropTypes.shape(),
        description: PropTypes.shape(),
        btn: PropTypes.string,
        dateTemp: PropTypes.string,
      })
    ),
  }),
};

ExclusiveOffer.defaultProps = {
  data: {
    primary: {
      title: {
        raw: [
          {
            type: "preformatted",
            text: "Exclusive Offer from our Partners",
            spans: [],
          },
        ],
      },
      description: {
        raw: [
          {
            type: "preformatted",
            text:
              "By joining through Draper's programe, you will have access to all these awesome exclusive offer from our partners lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium ve.",
            spans: [],
          },
          {
            type: "preformatted",
            text:
              "By joining through Draper's programe, you will have access to all these awesome exclusive offer from our partners lorem.",
            spans: [],
          },
        ],
      },
    },
    list: [
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand2,
        title: "Leadership: How to define your vision.",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand3,
        title: "Digital Product Management: Modern Fundamentals",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand2,
        title: "Digital Product Management: Modern Fundamentals",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand3,
        title: "Leadership: How to define your vision.",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
    ],
  },
};

export default ExclusiveOffer;
