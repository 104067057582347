import React from "react";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";

const colors = [
  color.secondary.yellowcream,
  color.primary.green40,
  color.secondary.danube,
  color.secondary.victoria,
  color.primary.blue,
];

const OurProcess = ({ data, ...props }) => {
  const { items, primary } = data || {};
  const Item = ({ data, step }) => (
    <WrapperContent isLeft={step % 2 !== 0}>
      <WrapClipPath isLeft={step % 2 !== 0}>
        <ClipPath color={colors[step - 1]} isLeft={step % 2 !== 0}>
          <ClipPathTile
            weight={typography.weight.regular}
            size={typography.size.s2}
            tag="p"
            color={color.primary.white}
            font={typography.type.primary}
          >
            STEP
          </ClipPathTile>
          <ClipPathStep
            tag="h4"
            size={typography.size.l3}
            capital="uppercase"
            font="Alternate Gothic No1 D"
            weight={typography.weight.regular}
            color={color.primary.white}
          >
            0{step}
          </ClipPathStep>
        </ClipPath>
        <WrapLine isLeft={step % 2 !== 0} color={colors[step - 1]}>
          <ClipPathName
            isLeft={step % 2 !== 0}
            tag="h5"
            size={typography.size.l1}
            capital="uppercase"
            font="Alternate Gothic No1 D"
            weight={typography.weight.regular}
            color={color.netraul.black100}
          >
            {data.process_step.text}
          </ClipPathName>
        </WrapLine>
      </WrapClipPath>
    </WrapperContent>
  );
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <ContainerClipPath>
        {items &&
          items.map((data, index) => (
            <Item data={data} key={index} step={index + 1} />
          ))}
      </ContainerClipPath>
      <ContainerText>
        <Title
          tag="h3"
          size={typography.size.code}
          capital="uppercase"
          font="Alternate Gothic No1 D"
          color={color.primary.blue}
          weight={typography.weight.regular1}
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Title>
        <Description
          // tag="p"
          font="Oxygen"
          color={color.netraul.black80}
          weight={typography.weight.regular}
          size={typography.size.m1}
          lineHeight={30}
        >
          <RichText
            render={primary?.description?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Description>
      </ContainerText>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 147px;
  padding: 0 148px 0 132px;
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    padding: 0 20px;
    margin-top: 100px;
  }
  @media (max-width: 650px) {
    flex-direction: column-reverse;
    padding: 0 20px;
    margin-top: 80px;
  }
`;

const WrapperContent = styled.div`
  background-color: ${color.primary.white};
  display: flex;
  width: 100%;
  margin-top: -23px;
  ${(props) =>
    props.isLeft
      ? "justify-content: flex-end;"
      : "justify-content: flex-start;"};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    margin-top: -23px;
  }
  @media (max-width: 650px) {
    margin-top: 24px;
  }
  @media (max-width: 374px) {
    margin-top: 40px;
  }
`;

const WrapClipPath = styled.div`
  display: flex;
  align-items: center;
  width: calc(50% + 150px);
  flex-direction: ${(props) => (props.isLeft ? "row" : "row-reverse")};
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 992px) {
    width: 70%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const ClipPath = styled.div`
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  height: 126px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  @media (max-width: 1200px) {
    width: 130px;
    height: 116px;
  }
  @media (max-width: 650px) {
    width: 102px;
    height: 86px;
  }
`;

const ClipPathTile = styled(Text)`
  opacity: 0.8;
  letter-spacing: 0.2em;
  line-height: 22px;
`;

const ContainerClipPath = styled.div`
  min-width: calc(100% * 2 / 3);
  padding-right: 10%;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding-right: 6%;
  }
  @media (max-width: 992px) {
    padding-right: 0;
    margin-top: 80px;
  }
  @media (max-width: 650px) {
    padding-right: 0;
    margin-top: 16px;
  }
`;
const ClipPathStep = styled(Text)`
  @media (max-width: 1200px) {
  }
  @media (max-width: 650px) {
    font-size: 30px !important;
  }
`;
const WrapLine = styled.div`
  border: 2px solid ${(props) => props.color};
  @media (max-width: 1200px) {
  }
  @media (max-width: 650px) {
    border-width: 1px;
  }
  position: relative;
  flex-grow: 1;
  height: 0px;
  &::before {
    content: "";
    position: absolute;
    ${(props) => (!props.isLeft ? "left : -2px;" : "right: -2px;")}
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color};
    @media (max-width: 1200px) {
    }

    @media (max-width: 650px) {
      width: 10px;
      height: 10px;
    }
  }
`;
const ClipPathName = styled(Text)`
  position: absolute;
  top: 20px;
  letter-spacing: -0.015em;
  height: 36px;
  ${(props) =>
    !props.isLeft ? "left: 20px; width: 153px;" : "right:-10px; width: 153px;"};
  @media (max-width: 1320px) {
    font-size: 30px !important;
    width: 41%;
    ${(props) => (!props.isLeft ? "" : "right:20px; text-align: right")};
  }
  @media (max-width: 650px) {
    width: 100%;
    top: 14px;
    ${(props) => (!props.isLeft ? "" : "text-align:right;")};
  }
  @media (max-width: 650px) {
    width: 100%;
    /* font-size: 30px !important; */
    line-height: 36px;
    top: 7px;
    ${(props) => (!props.isLeft ? "" : "text-align:right;")};
  }
`;
const ContainerText = styled.div``;
const Title = styled(Text)`
  line-height: 90px;
  width: 255px;
  display: block;
  margin-bottom: 47px;

  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    font-size: ${typography.size.l4}px !important;
    line-height: 62px;
    font-weight: ${typography.weight.regular1};
    margin-bottom: 30px;
  }
`;
const Description = styled(Text)`
  span {
    line-height: 30px;
    font-size: 20px;

    @media (max-width: 1200px) {
    }
    @media (max-width: 650px) {
      margin-top: 30px;
      font-size: 16px !important;
      line-height: 26px;
    }
  }
`;

OurProcess.propTypes = {
  listItem: PropTypes.arrayOf(
    PropTypes.shape({
      isLeft: PropTypes.bool,
      step: PropTypes.string,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
};

OurProcess.defaultProps = {
  primary: {
    title: {
      raw: [
        {
          type: "preformatted",
          text: "Our process",
          spans: [],
        },
      ],
    },
    description: {
      raw: [
        {
          type: "preformatted",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at erat id nisl convallis placerat in elementum mauris. ",
          spans: [],
        },
        {
          type: "preformatted",
          text:
            "Curabitur mollis tempus orci, eu egestas ante pretium vel. Aenean volutpat augue vitae enim pharetra vestibulum. ",
          spans: [],
        },
      ],
    },
  },
  list: [
    {
      isLeft: true,
      step: "01",
      name: "Identifying your needs",
      color: color.secondary.yellowcream,
    },
    {
      isLeft: false,
      step: "02",
      name: "We check in with you",
      color: color.primary.green40,
    },
    {
      isLeft: true,
      step: "03",
      name: "You meet curated needs",
      color: color.secondary.danube,
    },
    {
      isLeft: false,
      step: "04",
      name: "Agreement to work",
      color: color.secondary.victoria,
    },
    {
      isLeft: true,
      step: "05",
      name: "Start work",
      color: color.primary.blue,
    },
  ],
};

export default OurProcess;
