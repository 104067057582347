import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import author1 from "../../images/Service-staup-author1.png";
import author2 from "../../images/Service-staup-author2.png";
import author3 from "../../images/Service-staup-author4.png";
import { color, typography } from "../../shared/style";
import { ItemAuthorList } from "../DenContainer/AuthorContainer/ItemAuthorList";

const StaupsWithUs = ({  review_list, ...props }) => {
  const {primary, items} = review_list || {};
  if (review_list=== undefined || !items || !primary ) return null;
  return (
    <WrapperSection>
      <TitleSection
        size={typography.size.code}
        tag="h3"
        weight={typography.weight.regular1}
        color={color.primary.blue}
        font={typography.type.code}
        capital="uppercase"
        lineHeight={90}
      >
        <RichText
          render={primary?.title?.raw}
          htmlSerializer={htmlSerializer}
        />
      </TitleSection>
      {/* <ContainerBrand>
        {data.items &&
          data?.items.map((item, index) => (
            <WrapItem key={index}>
              <BrandImage key={index}>
                <Image
                  src={item?.partner?.document?.data?.logo?.url}
                  alt="image"
                ></Image>
              </BrandImage>
            </WrapItem>
          ))}
      </ContainerBrand> */}

      <WrapListAuthor className="service-author">
        <ItemAuthorList items={items || []} noLink={true} />
      </WrapListAuthor>
    </WrapperSection>
  );
};

const WrapListAuthor = styled.div`
  margin-top: 88px;
  @media (max-width: 992px) {
    margin-top: 80px;
  }
  @media (max-width: 756px) {
    margin-top: 0px;
    & > div {
      margin-top: 0;
    }
  }
`;

const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 150px;
  padding: 0 131px;
  @media (max-width: 1200px) {
    padding: 0px 20px 0px;
  }
  @media (max-width: 992px) {
    padding: 0px 20px 0px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
    margin-top: 114px;
  }
  .service-author {
    .slick-slide > div > div {
      background: linear-gradient(to bottom, #fff 43px, #f4f6f8 0);
    }
    .slick-arrow {
      top: -50px;
    }
  }
`;

// const Image = styled.img`
//   width: 100%;
//   object-fit: contain;
// `;

const TitleSection = styled(Text)`
  display: block;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  span {
    letter-spacing: -0.015em;
  }
  @media (max-width: 1440px) {
    width: 60%;
  }
  @media (max-width: 1240px) {
    width: 70%;
  }
  @media (max-width: 878px) {
    width: 80%;
  }
  @media (max-width: 773px) {
    font-size: ${typography.size.l4}px!important;
    line-height: 62px;
    margin-bottom: 40px;
  }
  @media (max-width: 483px) and (min-width: 426px) {
    width: 385px;
    margin: 0 auto;
  }
`;
// const ContainerBrand = styled.div`
//   display: none;
//   flex-wrap: wrap;
//   box-sizing: border-box;
// `;

// const BrandImage = styled.div`
//   height: 100%;
//   /* max-width: 110px; */
//   max-height: 50px;
//   img {
//     height: 100%;
//   }
// `;
// const WrapItem = styled.div`
//   flex: 0 0 16.666%;
//   max-width: 16.666%;
//   padding: 25px 50px;
//   box-sizing: border-box;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @media (max-width: 1200px) {
//     padding: 16px 32px;
//   }

//   @media (max-width: 992px) {
//     flex: 0 0 25%;
//     max-width: 25%;
//     margin-bottom: 40px;
//     padding: 0 40px 0 20px;
//   }
//   @media (min-width: 377px) and (max-width: 650px) {
//     flex: 0 0 33.333%;
//     max-width: 33.333%;
//     padding: 0 36px 0 18px;
//   }
//   @media (max-width: 376px) {
//     flex: 0 0 50%;
//     max-width: 50%;
//     padding: 0 36px 0 18px;
//     margin-bottom: 40px;
//   }
// `;

StaupsWithUs.propsTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      position: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

StaupsWithUs.defaultProps = {
  list: [
    {
      image: author1,
      name: "Simon JD",
      position: "Co-founder & CEO Travellio",
      rating: 4,
      description:
        "Ornare massa eget egestas purus viverrasan in nisl. Sit amet luctus venenatis lectus magna fring ut venenatis tellus in metus vulputate eu scelerisque felis. Felis done.Felis donec et odio pellentesque di scelerisque felis. Ornare massa eget egest.",
    },
    {
      image: author2,
      name: "Jane Cooper",
      position: "Co-founder & CEO Travellio",
      rating: 4,
      description:
        "Ornare massa eget egestas purus viverrasan in nisl. Sit amet luctus venenatis lectus magna fring ut venenatis tellus in metus vulputate eu scelerisque felis. Felis done Felis donec et odio pellentesque di scelerisque felis. Ornare massa eget egest.Ornare massa eget egestas purus viverrasan in nisl.",
    },
    {
      image: author3,
      name: "Albert Flores",
      position: "Marketing Coordinator - Gillete ",
      rating: 4,
      description:
        "Ornare massa eget egestas purus viverrasan in nisl. Sit amet luctus venenatis lectus magna fring ut venenatis tellus in metus vulputate eu scelerisque felis. Felis done. Felis donec et odio pellentesque di scelerisque felis. Ornare massa eget egest.",
    },
  ],
};

export default StaupsWithUs;
