import React, { useState } from "react";
import image1 from "../../images/Service-Rectangle-40.png";
import image2 from "../../images/Service-Rectangle-2.png";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import { Link } from "../../components/Link/Link";
import { Button } from "../../components/Button/Button";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import {
  Widget,
  PopupButton,
  SliderButton,
  Popover
} from "@typeform/embed-react";
import { createPopup } from "@typeform/embed";
import { redirectPage } from "../../hooks/redirect";

const DetailService = ({ data, ...props }) => {
  const { items } = data || {};
  const Item = ({ item }) => (
    <WrapperContent isLeft={item.image_position === "left"}>
      <WrapImage isLeft={item.image_position === "left"}>
        <Image
          isLeft={item.image_position === "left"}
          color={item.shadow_color}
          src={item.image?.url}
        ></Image>
      </WrapImage>
      <Content isLeft={item.image_position === "left"}>
        <Title
          tag="h4"
          capital="uppercase"
          font="Alternate Gothic No1 D"
          color={color.primary.blue}
          weight={typography.weight.regular1}
        >
          <RichText render={item?.title.raw} htmlSerializer={htmlSerializer} />
        </Title>
        <Description
          // tag="p"
          weight={typography.weight.regular}
          color={color.netraul.black100}
          font="Oxygen"
          lineHeight={26}
        >
          <RichText
            render={item?.description.raw}
            htmlSerializer={htmlSerializer}
          />
        </Description>
        {item.link ? (
          <ButtonExtended
            onClick={() =>
              redirectPage({
                url: item?.link?.url,
                type: item?.link?.type,
                link_type: item?.link?.link_type,
              })
            }
            type="primary"
          >
            {item?.link_label?.text}
          </ButtonExtended>
        ) : (
          <PopupButtonCustom id="MhokaAFd"
          // style={{
          //   all: "unset",
          //   fontFamily: "Oxygen",
          //   display: " inline-block",
          //   maxWidth: "100%",
          //   whiteSpace: "nowrap",
          //   overflow: "hidden",
          //   textOverflow: "ellipsis",
          //   backgroundColor: "#204370",
          //   color: "#ffffff",
          //   fontSize: "20px",
          //   borderRadius: "25px",
          //   padding: " 0 33px",
          //   fontWeight: "bold",
          //   height: "50px",
          //   cursor: "pointer",
          //   lineHeight: "50px",
          //   textAlign: "center",
          //   margin: "0",
          //   textDecoration: "none",
          // }}
          >Get Support</PopupButtonCustom>
        )}
        <Popover id="MhokaAFd" />
      </Content>
    </WrapperContent>
  );
  if (data === undefined || !items) return null;
  return (
    <WrapperSection>
      {items && items.map((item, index) => <Item item={item} key={index} />)}
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 150px;
  @media (max-width: 650px) {
    margin-top: 80px;
  }
`;

const WrapperContent = styled.div`
  background-color: ${color.primary.white};
  display: flex;
  display: flex;
  margin-bottom: 150px;
  flex-direction: ${(props) => (props.isLeft ? "row" : "row-reverse")};
  @media (max-width: 1200px) {
    margin-bottom: 120px;
  }

  @media (max-width: 992px) {
    margin-bottom: 100px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 80px;
  }
`;

const WrapImage = styled.div`
  width: 48%;
  box-sizing: border-box;
  ${(props) => (props.isLeft ? `padding-left:41px;` : `padding-right:40px; `)};
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 438px;
  object-fit: cover;
  ${(props) =>
    props.isLeft
      ? `box-shadow: -41px 54px 1px ${color.secondary.danube};`
      : `box-shadow: 41px 54px 1px ${props.color}`};

  @media (max-width: 992px) {
    height: auto;
    ${(props) =>
    props.isLeft
      ? `box-shadow: -41px 54px 1px ${color.secondary.danube};`
      : `box-shadow: 41px 54px 1px ${props.color}`};
  }
  @media (max-width: 650px) {
    ${(props) =>
    props.isLeft
      ? `box-shadow: -20px 26px 1px ${color.secondary.danube};`
      : `box-shadow: 20px 26px 1px ${props.color}`};
  }
`;

const Content = styled.div`
  width: 52%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.isLeft
      ? `padding-left: 127px; padding-right: 130px;`
      : `padding-left: 130px; padding-right: 127px`};

  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 67px;
  }
`;
const Title = styled(Text)`
  line-height: 56px;
  margin-bottom: 32px;
  @media (max-width: 1200px) {
    font-size: ${typography.size.l2};
    line-height: 56px;
  }
  @media (max-width: 992px) {
    font-size: ${typography.size.l2};
    line-height: 40px;
  }
  @media (max-width: 650px) {
    font-size: ${typography.size.l1};
    line-height: 36px;
    margin-bottom: 24px;
  }
`;
const Description = styled(Text)`
  div {
    margin-top: 32px;
    @media (max-width: 650px) {
      margin-top: 24px;
    }
  }
`;

const Btn = styled(Link)`
  display: inline-block;
  font-size: 16px;
  margin-top: 32px;
  border-bottom: 1px solid #204370;
  font-weight: bold;
  width: fit-content;
  @media (max-width: 650px) {
    margin-top: 30px;
  } ;
`;

const PopupButtonCustom = styled(PopupButton)`
  font-family: "Oxygen";
  position: relative;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  border-radius: 40px;
  width: fit-content;
  padding: 20px 45px;
  font-weight: ${typography.weight.bold};
  transition-duration: 0.5s;
  color: ${color.primary.white};
  background: ${color.primary.blue};
  border: 1px solid ${color.primary.blue};
  margin-top: 20px;
  &:hover {
    color: ${color.primary.blue};
    background: ${color.primary.white};
    border: 1px solid ${color.primary.blue};
  }
`;

const ButtonExtended = styled(Button)`
  line-height: 26px;
  padding: 20px 45px;
  height: 65px;
  white-space: nowrap;
  width: fit-content;
  margin-top: 20px;
`;

DetailService.propTypes = {
  data: PropTypes.shape({
    list: PropTypes.shape({
      image: PropTypes.string,
      isLeft: PropTypes.bool,
      shadowColor: PropTypes.string,
      title: PropTypes.shape(),
      description: PropTypes.shape(),
      btn: PropTypes.string,
    }),
  }),
};

DetailService.defaultProps = {
  data: {
    list: [
      {
        image: image1,
        isLeft: true,
        shadowColor: color.secondary.emerald,
        title: {
          raw: [
            {
              type: "preformatted",
              text:
                "We help you find affordable tech support who we know are experts in their field",
              spans: [],
            },
          ],
        },

        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "Technology is a driving factor behind most of the successful startups of our day. But finding the right developers for your idea? For many that we work with, the tech landscape can be daunting. Sometimes just knowing where to start to bring your idea to life.",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "You can find supports from our experts who can guide you in your tech journey. We have carefully curated partners that are supportive to entrepreneurs. This saves you the hassle of finding the right team!",
              spans: [],
            },
          ],
        },
        btn: "Get supports",
      },
      {
        image: image2,
        isLeft: false,
        shadowColor: color.secondary.emerald,
        title: {
          raw: [
            {
              type: "preformatted",
              text:
                "We also guide you to face the toughest challenges and build a long last business",
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "Get your ball rolling in the early stage is tricky, you have a lot of important questions. Is there a market for my product? Will my product help people solve their problems? What should I build? And so it continues.",
              spans: [],
            },
            {
              type: "preformatted",
              text:
                "Fortunately, you don't have to deal with this alone. At Draper, our team can work along with you from idea validation to full product build.",
              spans: [],
            },
          ],
        },
        btn: "Build your dream with us",
      },
    ],
  },
};

export default DetailService;
