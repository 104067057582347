import React from "react";
import styled from "styled-components";
// import { Button } from "../../components/Button/Button";
import { Link } from "../../components/Link/Link";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";

const Summary = ({ data }) => {
  const { primary } = data || {};
  if (data===undefined || !primary) return null;
  return (
    <WrapperSection>
      <Header
        tag="h4"
        weight={typography.weight.regular1}
        color={color.primary.white}
        font={typography.type.code}
        size={typography.size.l3}
        capital="uppercase"
        lineHeight={56}
        align="center"
      >
        {primary?.title?.text}
      </Header>
      <Btn to={`mailto:${primary?.email_contact?.text}`} color={color.primary.blue}>
        {primary?.button_label?.text}
      </Btn>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.primary.blue};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;

const Header = styled(Text)`
  width: 796px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    width: 600px;
  }
  @media (max-width: 650px) {
    width: 335px;
    font-size: 36px !important;
    line-height: 36px;
  }
`;

const Btn = styled(Link)`
  /* display: inline-block;
  font-size: 16px;
  margin-top: 32px;
  border-bottom: 1px solid #204370;
  font-weight: bold;
  width: fit-content; */
  font-size: 16px;
  margin-top: 32px;
  border: 1px solid #204370;
  font-weight: bold;
  height: 65px;
  background-color: ${color.primary.yellow};
  display: flex;
  align-items: center;
  padding: 0 45px;
  border-radius: 40px;
  color: ${color.netraul.black100};
  transition-duration: 0.5s;
  &:hover {
    color: ${color.netraul.black100};
    background: ${color.primary.white};
    border: 1px solid ${color.secondary.yellow};
  }
  @media (max-width: 650px) {
    margin-top: 30px;
  } ;
`;

export default Summary;
