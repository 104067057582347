import React from "react";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import ideaIcon from "../../images/Service-idea.png";
import clickIcon from "../../images/Service-click.png";
import roadmapIcon from "../../images/Service-roadmap.png";
import MVPIcon from "../../images/Service-MVP.png";
import buildIcon from "../../images/Service-build.png";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import { Button } from "../../components/Button/Button";
import { redirectPage } from "../../hooks/redirect";

const ServicePackages = ({ data, ...props }) => {
  const { items, primary } = data || {};
  const Item = ({ item, index }) => (
    <WrapperContent>
      <Content>
        <WrapImage>
          <Image src={item.icon?.url} alt={item.icon?.alt}></Image>
        </WrapImage>
        <ContentDetail>
          <Title
            // tag="h5"
            size={typography.size.l1}
            // capital={index === 3 ? "initial" : "uppercase"}
            font="Alternate Gothic No1 D"
            color={color.netraul.black100}
            weight={typography.weight.regular1}
            lineHeight={36}
          >
            <RichText
              render={item?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <Description
            // tag="p"
            weight={typography.weight.regular}
            color={color.netraul.black100}
            font="Oxygen"
            lineHeight={26}
          >
            <RichText
              render={item?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Description>
        </ContentDetail>
      </Content>
    </WrapperContent>
  );
  if (!items || !primary) return null;
  return (
    <WrapperSection>
      <Container>
        <TitleHeader
          tag="h3"
          capital="uppercase"
          font="Alternate Gothic No1 D"
          color={color.primary.blue}
          weight={typography.weight.regular1}
        >
          <RichText
            render={primary.title.raw}
            htmlSerializer={htmlSerializer}
          />
        {/* <Btn 
          type="tertiary" 
          onClick={()=>{
              redirectPage({
                url: primary?.button_link?.url,
                type: primary?.button_link?.type,
                link_type: primary?.button_link?.link_type
              })
            }}
        >
          {primary?.button_label?.text}
        </Btn> */}
        </TitleHeader>
        {items &&
          items.map((item, index) => (
            <Item item={item} index={index} key={index} />
          ))}
      </Container>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  padding: 120px 131px;
  padding-bottom: calc(120px - 32px);
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    padding: 120px 20px 80px;
  }
  @media (max-width: 992px) {
    padding: 80px 20px 80px;
  }
  @media (max-width: 650px) {
    /* padding: 70px 20px 80px; */
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -17.5px;
  margin-right: -17.5px;
  box-sizing: border-box;
  align-items: stretch;
  @media (max-width: 1200px) {
    margin: 0 -12px;
  }
  @media (max-width: 650px) {
    margin: 0;
  }
`;

const WrapperContent = styled.div`
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
  padding-left: 17.5px;
  padding-right: 17.5px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
    padding: 0 12px;
  }
  @media (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }
`;

const Content = styled.div`
  height: calc(100% - 32px);
  padding: 32px;
  margin-bottom: 32px;
  box-sizing: border-box;
  background-color: ${color.primary.white};

  @media (max-width: 650px) {
    padding: 20px 20px 30px;
    margin-bottom: 20px;
    height: auto;
  }
`;

const ContentDetail = styled.div``;

const TitleHeader = styled(Text)`
  display: flex;
  gap: 30px;
  flex-direction: column;
  width: 343px;
  height: 180px;
  letter-spacing: -0.015em;
  width: calc(100% / 3);
  padding: 0 17.5px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    width: calc(100% / 3);
    padding: 0 12px;
  }
  @media (max-width: 992px) {
    flex: 0 0 50%;
    width: 50%;
    span:first {
      font-size: 90px !important;
      line-height: 90px !important;
    }
  }
  @media (max-width: 650px) {
    font-size: 62px !important;
    line-height: 62px;
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    height: auto;
    span:first {
      font-size: 62px !important;
      line-height: 62px;
    }
  }
`;

const Btn = styled(Button)`
  width: fit-content;
  max-width: 100%;
  /* @media(max-width: 992px) {
    font-size: 16px !important;
    line-height: inherit !important;
  }
  @media(max-width: 650px) {
    font-size: 16px !important;
    line-height: inherit !important;
  } */
`

const WrapImage = styled.div`
  max-width: 40px;
  max-height: 40px;
  height: 100%;
  width: 100%;
  /* height: 40px; */
  /* background: ${color.primary.green40}; */
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

const Title = styled(Text)`
  line-height: 36px;
  margin: 29px 0 24px 0;
  display: block;
  span {
    text-transform: inherit;
  }
  @media (max-width: 650px) {
    font-size: ${typography.size.l1};
    line-height: 36px;
    color: rgba(35, 35, 39, 0.9);
    span {
      font-size: 36px !important;
      line-height: 36px;
    }
  }
`;
const Description = styled(Text)``;

ServicePackages.propTypes = {
  data: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.shape(),
      })
    ),
  }),
};
ServicePackages.defaultProps = {
  data: {
    list: [
      {
        icon: ideaIcon,
        title: {
          raw: [
            {
              type: "preformatted",
              text: "Idea validation",
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "When you have lots of passion for an idea and are a little unclear on the path to take. Our team can help you validate your idea.",
              spans: [],
            },
          ],
        },
      },
      {
        icon: clickIcon,
        title: {
          raw: [
            {
              type: "preformatted",
              text: "Design or rapid prototyping",
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "When you have lots of passion for an idea and are a little unclear on the path to take. Our team can help you validate your idea.",
              spans: [],
            },
          ],
        },
      },
      {
        icon: roadmapIcon,
        title: {
          raw: [
            {
              type: "preformatted",
              text: "Product roadmap",
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "A product road map defines a shared for truth of how a product or solution can progress over time. At Draper, we align you with resources to communicate how you meet short and long term goals.",
              spans: [],
            },
          ],
        },
      },
      {
        icon: MVPIcon,
        title: {
          raw: [
            {
              type: "preformatted",
              text: `MVP <span style="text-transform: lowercase;">(Minimum viable product)</span>`,
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "When you have a product roadmap in place, it’s time to build with just enough features to gauge feedback for future product development.",
              spans: [],
            },
          ],
        },
      },
      {
        icon: buildIcon,
        title: {
          raw: [
            {
              type: "preformatted",
              text: "Full build",
              spans: [],
            },
          ],
        },
        description: {
          raw: [
            {
              type: "preformatted",
              text:
                "When the business planning, budgeting, and the team are ready to take the product online, we can help you with your architecting, engineering, and Q&A to make sure of a smooth go-live!",
              spans: [],
            },
          ],
        },
      },
    ],
  },
};
export default ServicePackages;
